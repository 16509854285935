import React from 'react';
import logo from './bounce_logo.svg';
import './App.css';

function App() {
    return (
        <div className="App">
            <header className="App-header"></header>
            <div className="main_container">
                <div className='logo_container'>
                    <img src={logo} className="App-logo" alt="logo"/>
                    <meta http-equiv="refresh" content="0.5;url=https://bounceup.io/" />
                        
                </div>
                <div className='text_container'>
                    <p>Please wait while being redirected to our new domain.</p>
                    <p>If you are not redirected within a few seconds please click on the following link:</p>
                    <p><a href="https://bounceup.io">https://bounceup.io</a></p>
                </div>
            </div>
        </div>
    );
}

export default App;
